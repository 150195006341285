import React from "react"
import { Link } from "gatsby"
import '../styles/utilitary.css'
import '../styles/recipe3.css'

import $ from 'jquery' // important: case sensitive.
import cardfoto from "../images/carne2.png" // Tell webpack this JS file uses this image
import recipeicon from "../images/recipe-icon.png" // Tell webpack this JS file uses this image

import Layout from "../components/layout"
import SEO from "../components/seo"
import cardfoto1 from "../images/steak-card.jpg" // Tell webpack this JS file uses this image
import cardfoto2 from "../images/nacos-card.jpg" // Tell webpack this JS file uses this image
import cardfoto3 from "../images/Bifemanteiga-card.jpg" // Tell webpack this JS file uses this image
import iconnutri from "../images/icon-nutri.png" // Tell webpack this JS file uses this image



const ReceitaC = () => (
  <Layout>
    <SEO title="Joe's Butcher - Receita 2" />

    <div id="rec3-section-1">
        <div className="wrapper-foto">
    </div>

        <div className="wrapper">
        <div className="titulo aller-display">
            Bifes com Manteiga e Alecrim com Batata Dourada
        </div>
        <div className="desc sneak-regular">
        A carne tenra e macia, envolvida num molho cremoso, propicia-lhe uma experiência plena de sabor.
        </div>
        </div>
    </div>
    <div id="rec3-section-2">
        <div className="wrapper">
            <div className="left-col">
                <div className="card"> 
                <div className="contentor">
                    <div className="foto">
                    {/* <img src={cardfoto} alt="Logo" /> */}

                    </div>
                    <div className="title absara">
                        Ingredientes
                    </div>
                    <div className="ingrediente ">
                        <div className="number absara">
                            1
                        </div>
                        <div className="text sneak-regular">  
                         Emb. de Bifes
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            400g 
                        </div>
                        <div className="text sneak-regular">  
                        Batata
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                          40g
                        </div>
                        <div className="text sneak-regular">  
                        Manteiga
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            1
                        </div>
                        <div className="text sneak-regular">  
                        Dente de Alho
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            1
                        </div>
                        <div className="text sneak-regular">  
                         Folha de Louro
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            1
                        </div>
                        <div className="text sneak-regular">  
                        Ramo de Alecrim
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            Q/B
                        </div>
                        <div className="text sneak-regular">  
                         Sal e Pimenta
                        </div>
                        
                    </div>
                
                
                  
                    
                    
                </div>
               
                </div>
            </div>
            <div className="right-col">

            <div className="card">
                    <div className="contentor">
                    <div className="foto">
                    {/* <img src={cardfoto} alt="Logo" /> */}

                    </div>
                    <div className="title absara">
                        Receita
                    </div>
                    <div className="ingrediente ">
                        <div className="number absara">
                            1 - 
                        </div>
                        <div className="text sneak-regular">  
                        Retire os bifes da embalagem 20 minutos antes da confeção e deixe à temperatura ambiente.
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            2 - 
                        </div>
                        <div className="text sneak-regular">  
                       Corte as batatas em quartos e coloque a cozer num tacho com água e sal. Escorra e reserve.
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            3 - 
                        </div>
                        <div className="text sneak-regular">  
                        Adicione os bifes numa frigideira bem quente, tempere com sal e pimenta e frite ao ponto desejado, como o alho, o louro, o alecrim e a manteiga.
                        </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            4 - 
                        </div>
                        <div className="text sneak-regular">  
                        Numa segunda frigideira, salteie as batatas até ficarem douradas.                  
                          </div>
                        
                    </div>
                    <div className="ingrediente">
                        <div className="number absara">
                            5 - 
                        </div>
                        <div className="text sneak-regular">  
                        Sirva de imediato.                
                          </div>
                        
                    </div>
       
                  
                 
                    
                </div>
               </div>
            </div>
        </div>
    </div>
    <div id="section-nutri">
    <div className="caracts">
            
            <div className="wrapper">
           
                <div className="card-caracts">
                    <div className="icon-nutri">
                    <img src={iconnutri} alt="ingredient" />

                    </div>
                <div className="main-titulo absara">
                   Valores Nutricionais
                </div>
                <div className="contentor">
                <div className="col-1">
                        <div className="titulo absara">
                            Energia Kcal
                        </div>
                        <div className="titulo absara">
                            Lipidos g
                        </div>
                        <div className="titulo absara">
                            Saturados g
                        </div>
                        <div className="titulo absara">
                            Hidratos g
                        </div>
                        <div className="titulo absara">
                            Açucares g
                        </div>
                        <div className="titulo absara">
                            Proteinas g
                        </div>
                        <div className="titulo absara">
                            Fibra g
                        </div>
                     
                    </div>
                    <div className="col-2">
                    <div className="titulo sneak-regular">
                            678,4
                        </div>
                        <div className="titulo sneak-regular">
                            34,7
                        </div>
                        <div className="titulo sneak-regular">
                           14,0
                        </div>
                        <div className="titulo sneak-regular">
                          54,9
                        </div>
                        <div className="titulo sneak-regular">
                         7,3
                        </div>
                        <div className="titulo sneak-regular">
                          36,6
                        </div>
                        <div className="titulo sneak-regular">
                         6,2
                        </div>
                       
                        </div>
                </div>
                   
                  
                    
                    
                </div>
            </div>
        </div>
    </div>
    {/* <div id="rec3-section-3">
        <div className="wrapper">
            <div className="left-col">
            <img src={cardfoto3} alt="Logo" />

            </div>
            <div className="right-col">
                    <div className="wrapper sneak-regular">
                        <div className="conteudo">
                        <img className="img" src={recipeicon} alt="Logo" /><br></br>
                        Este vídeo exemplificativo contém todos os passos necessários para elaborar a receita utilizando as melhores técnicas.
                        </div>
                        
                    </div>
                </div>
        </div>
    </div> */}


  </Layout>
)


export default ReceitaC
